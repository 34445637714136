<div class="modal-content-confirm">
    <h1 mat-dialog-title class="mx-0 my-0" style="margin: 0; padding-left: 0; font-size: 1.5rem;">
        {{ confirmData.title || 'Confirm'}}
    </h1>
    <div class="modal-body mx-0 my-0">
        <p>{{ confirmData.message || 'Are you sure?'}}</p>
    </div>

    <!-- ASI ESTABA ANTES -->
    <!-- <div class="modal-body" [ngClass]="dynamicClass? dynamicClass: ''">
        <p>{{message || 'Are you sure?'}}</p>
    </div> -->
    
    <div class="modal-footer" >
        <p *ngIf="counter != 0 || confirmData.textTimer != undefined" class="custom-timer">
            {{counter}}s
        </p>
        <div *ngIf="!confirmData.hideCancelmButtom && confirmData.textBoton.length > 1">
            <button mat-stroked-button [color]="cancelColor" (click)="close()">
                {{confirmData.textBoton.length > 0?  confirmData.textBoton[1]: 'Cancel'}}
            </button>
        </div>
        <button style="margin-left: 10px" mat-flat-button [color]="okColor" (click)="confirm()" *ngIf="!hideConfirmButtom" cdkFocusInitial>
            {{confirmData.textBoton.length > 0? confirmData.textBoton[0]: 'OK'}}
        </button>
    </div>
</div>